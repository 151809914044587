import DevelopmentConfig from "./development";
import { IConfiguration } from "./getConfig.interfaces";
import ProductionConfig from "./production";
import StagingConfig from "./staging";

const getConfig = (): IConfiguration => {
  if (process.env.REACT_APP_CONFIG === "development") {
    return DevelopmentConfig;
  }
  if (process.env.REACT_APP_CONFIG === "staging") {
    return StagingConfig;
  }
  return ProductionConfig;
};

const config: IConfiguration = getConfig();
export default config;

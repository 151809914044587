import { Stack, Typography } from "@mui/material";
import React, { ErrorInfo, ReactNode } from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";

import RauliLogo from "./RauliLogo";

export interface IRauliError extends WithTranslation {
  children: ReactNode;
  useMinimal?: boolean;
}

export type TErrorType = "network" | null;

export interface IErrorState {
  hasError: boolean;
  errorType: TErrorType;
  errorText?: string;
}

class RauliError extends React.Component<IRauliError, IErrorState> {
  constructor(props: IRauliError) {
    super(props);
    this.state = {
      hasError: false,
      errorType: null,
      errorText: undefined,
    };
  }

  static getDerivedStateFromError(error: Error): IErrorState {
    let errorType: TErrorType = null;

    switch (error.name) {
      case "ChunkLoadError":
        errorType = "network";
        break;
      default:
        break;
    }

    return {
      hasError: true,
      errorType: errorType,
      errorText: typeof error === "string" ? error : undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(error);
    console.log(error.name);
    console.log(errorInfo);
  }

  render() {
    const { t, useMinimal } = this.props;

    if (this.state.hasError) {
      const errorContent = (
        <>
          <Typography>{t("Something went wrong!")}</Typography>
          {this.state.errorType && (
            <Typography>
              {this.state.errorType === "network" &&
                t(
                  "Page loading failed. Please check your connectivity and reload the page."
                )}
            </Typography>
          )}
          <Typography>
            <Trans>
              Try{" "}
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  window.location.reload();
                }}
              >
                reloading the page
              </a>{" "}
              or go back to the <a href="/">frontpage</a>.
            </Trans>
          </Typography>
          {this.state.errorText && (
            <Typography variant="caption">
              {t("Error: {{ errorText }}", { errorText: this.state.errorText })}
            </Typography>
          )}
        </>
      );

      if (useMinimal) {
        return (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              "& p": {
                lineHeight: 1,
              },
            }}
          >
            {errorContent}
          </Stack>
        );
      }

      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          sx={{
            width: "100%",
            paddingTop: "10%",
          }}
        >
          <RauliLogo withoutLink />
          {errorContent}
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(RauliError);

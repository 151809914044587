import { Settings } from "luxon";
import i18n from "../translations/i18n";
import {
  FALLBACK_LANGUAGE,
  SUPPORTED_LANGUAGES,
  TSupportedLanguages,
} from "../translations/i18n.interfaces";

export const setLuxonLocale = (language: TSupportedLanguages) => {
  Settings.defaultLocale = SUPPORTED_LANGUAGES.includes(language)
    ? language
    : FALLBACK_LANGUAGE;
};

const changeLanguage = (language: TSupportedLanguages) => {
  i18n.changeLanguage(language);
  setLuxonLocale(language);
};

i18n.on("languageChanged", (lng: TSupportedLanguages) => {
  console.log("Language changed to", lng);
  setLuxonLocale(lng);
});

export default changeLanguage;

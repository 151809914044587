import { TSupportedLanguages } from "../translations/i18n.interfaces";
import { Components } from "./components";
import { IMedia, TMediaProductGroup } from "./media.interfaces";

interface IPresortedMedia extends IMedia {
  index: number;
}

const getMedia = (language?: TSupportedLanguages): IMedia[] => {
  if (language === undefined) {
    language = "en";
  }

  return [
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "other",
      language: "en",
      title: "RAULI APP Tutorial",
      url: "https://youtu.be/Ur34bjbCp2g",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "other",
      language: "fi",
      title: "RAULI APP käyttöohje",
      url: "https://youtu.be/0nn-42uNq-Q",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "wall",
      language: "fi",
      title: "RAULI WALL suunnittelu",
      url: "https://youtu.be/Iu-46mvu9eA",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "flat",
      language: "fi",
      title: "RAULI APP tasakattoasennukset",
      url: "https://youtu.be/4Z9TnhBk4HU",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "flat",
      language: "en",
      title: "RAULI APP flat roof installations",
      url: "https://youtu.be/MEDUfDEH-bc",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "wall",
      language: "en",
      title: "RAULI WALL functionality",
      url: "https://youtu.be/PbRUAyuFaBw",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "black",
      language: "en",
      title: "RAULI BLACK installation standing seam",
      url: "https://youtu.be/hPwVr1XCcTc",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "black",
      language: "en",
      title: "RAULI BLACK installation tile roof",
      url: "https://youtu.be/eHa3h6g-SgY",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "black",
      language: "de",
      title: "RAULI BLACK Installation Ziegeldach",
      url: "https://youtu.be/0pT3BfMKq2M",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "black",
      language: "de",
      title: "RAULI BLACK Installation Stehfalzdach",
      url: "https://youtu.be/UQlVbHfloC8",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "black",
      language: "fi",
      title: "RAULI BLACK asennus saumakatolle",
      url: "https://youtu.be/vHmuuTrqXAk",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "black",
      language: "fi",
      title: "RAULI BLACK asennus tiilikatolle",
      url: "https://youtu.be/sp0Ck-ko_4k",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "other",
      language: "en",
      title: "Customer experience",
      url: "https://youtu.be/xz2WJGwZayw",
      sortOrder: 5,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "black",
      language: "en",
      title: "110 panels installed in 7h15mins",
      url: "https://youtu.be/_vSz0tTkKgE",
      sortOrder: 4,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "black",
      language: "en",
      title: "Fastest mounting",
      url: "https://youtu.be/FzBBb-2valk",
      sortOrder: 4,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "black",
      language: "en",
      title: "Snowload stress test",
      url: "https://youtu.be/qY7Q1ifpK-s",
      sortOrder: 4,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "other",
      language: "en",
      title: "Introduction",
      url: "https://youtu.be/t9SdxDk8S6c",
      sortOrder: 1,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "wall",
      language: "en",
      title: "RAULI WALL Introduction",
      url: "https://youtu.be/kqiG6TcGXwQ",
      sortOrder: 1,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "wall",
      language: "fi",
      title: "RAULI WALL esittely",
      url: "https://youtu.be/sB0NyhRNC58",
      sortOrder: 1,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "wall",
      language: "de",
      title: "RAULI WALL zur Wandmontage von Solarmodulen",
      url: "https://youtu.be/yP6NOdAQsek",
      sortOrder: 1,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "black",
      language: "en",
      title: "RAULI BLACK Introduction",
      url: "https://youtu.be/8CJPFG6sluA",
      sortOrder: 1,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "black",
      language: "fi",
      title: "RAULI BLACK esittely",
      url: "https://youtu.be/ALCGBqWc3HM",
      sortOrder: 1,
    },
    {
      archived: false,
      categories: ["info"],
      type: "youtube",
      group: "flat",
      language: "en",
      title: "RAULI FLAT introduction",
      url: "https://youtu.be/XtklRcxMQOg",
      sortOrder: 0,
    },
    {
      archived: true,
      categories: ["instruction"],
      type: "pdf",
      group: "black",
      language: "fi",
      title: "Harjakatto asennusohje",
      url: "/static/material/instructions/Asennusohje - 2022.pdf",
      sortOrder: 0,
    },
    {
      archived: true,
      categories: ["instruction"],
      type: "pdf",
      group: "black",
      language: "fi",
      title: "RAULI BLACK asennusohje",
      url: "/static/material/instructions/Rauli-BLACK-Asennusohje-FI-2023.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "pdf",
      group: "black",
      language: "fi",
      title: "RAULI BLACK asennusohje",
      url: "/static/material/instructions/Rauli-BLACK-Asennusohje-FI-2024.pdf",
      staticUrl: "harjakatto-asennusohje.pdf",
      sortOrder: 0,
    },
    {
      archived: true,
      categories: ["instruction"],
      type: "pdf",
      group: "black",
      language: "en",
      title: "RAULI BLACK installation instructions",
      url: "/static/material/instructions/Rauli-BLACK-InstallationManual-EN-2023.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "pdf",
      group: "black",
      language: "en",
      title: "RAULI BLACK installation instructions",
      url: "/static/material/instructions/Rauli-BLACK-InstallationManual-EN-2024.pdf",
      staticUrl: "black-installation-instructions.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "pdf",
      group: "wall",
      language: "fi",
      title: "RAULI WALL asennusohje",
      url: "/static/material/instructions/Rauli-Wall-AsennusOhje-2023-10-26-FI.pdf",
      staticUrl: "wall-asennusohje.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "pdf",
      group: "wall",
      language: "en",
      title: "RAULI WALL installation manual",
      url: "/static/material/instructions/Rauli-Wall-InstallationManual-2023-10-26-EN.pdf",
      staticUrl: "wall-installation-manual.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "wall",
      language: "en",
      title: "RAULI WALL installation",
      url: "https://youtu.be/rTa8LFdn8Sw",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "wall",
      language: "fi",
      title: "RAULI WALL asennus",
      url: "https://youtu.be/eHA9seSPy5c",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "youtube",
      group: "wall",
      language: "de",
      title: "RAULI WALL Installation",
      url: "https://youtu.be/1ZI4Ub2g5nk",
      sortOrder: 3,
    },
    {
      archived: false,
      categories: ["instruction", "info"],
      type: "link",
      group: "other",
      language: "en",
      title: "API documentation",
      url: "/doc/api",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "pdf",
      group: "flat",
      language: "fi",
      title: "RAULI FLAT asennusohje",
      url: "/static/material/instructions/Rauli-Flat-InstallationManual-FI-2023-11-30.pdf",
      staticUrl: "flat-asennusohje.pdf",
      sortOrder: 0,
    },
    {
      archived: true,
      categories: ["instruction"],
      type: "pdf",
      group: "flat",
      language: "en",
      title: "RAULI FLAT installation manual",
      url: "/static/material/instructions/Rauli-Flat-InstallationManual-EN-2023-11-30.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "pdf",
      group: "flat",
      language: "en",
      title: "RAULI FLAT installation manual",
      url: "/static/material/instructions/Rauli-Flat-InstallationManual-2024-01-15-EN-optimized.pdf",
      staticUrl: "flat-installation-manual.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction"],
      type: "pdf",
      group: "flat",
      language: "en",
      title: "RAULI FLAT Portrait installation manual",
      url: "/static/material/instructions/Rauli-Flat_Portrait-InstallationManual-ENG-2024-06.pdf",
      sortOrder: 0,
    },
    {
      archived: true,
      categories: ["info"],
      type: "pdf",
      group: "other",
      language: "en",
      title: "RAULI Catalog",
      url: "/static/material/catalog/Rauli-Catalog-2024-BLACK-WALL-FLAT.pdf",
      staticUrl: "catalog.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["info"],
      type: "pdf",
      group: "other",
      language: "en",
      title: "RAULI Catalog",
      url: "/static/material/catalog/Rauli-Catalog-2024-08-BLACK-WALL-FLAT.pdf",
      staticUrl: "catalog.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["info"],
      type: "pdf",
      group: "wall",
      language: "en",
      title: "RAULI WALL Catalog",
      url: "/static/material/catalog/Rauli-Catalog-2024-WALL.pdf",
      staticUrl: "catalog-wall.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["info"],
      type: "pdf",
      group: "black",
      language: "en",
      title: "RAULI BLACK Catalog",
      url: "/static/material/catalog/Rauli-Catalog-2024-BLACK.pdf",
      staticUrl: "catalog-black.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["info"],
      type: "pdf",
      group: "flat",
      language: "en",
      title: "RAULI FLAT Catalog",
      url: "/static/material/catalog/Rauli-Catalog-2024-FLAT.pdf",
      staticUrl: "catalog-flat.pdf",
      sortOrder: 0,
    },
    {
      archived: false,
      categories: ["instruction", "info"],
      type: "pdf",
      group: "wall",
      language: "en",
      title: "RAULI WALL technical product card",
      url: "/static/material/instructions/Rauli-Wall-TechnicalProductCard-2024-02-23-EN.pdf",
      sortOrder: 0,
    },
    ...[
      "11055",
      "11056",
      "11058",
      "11066",
      "11068",
      "11069",
      "11070",
      "11071",
      "11074",
      "11075",
      "11093",
      "11094",
      "11095",
      "11097",
      "11098",
      "11100",
      "11102",
      "11123",
      "11146",
      "11155",
      "11163",
      "11164",
      "11166",
      "11174",
      "11175",
      "11176",
      "11300",
      "11301",
      "11302",
      "11303",
      "11304",
      "11305",
      "11306",
      "11311",
      "11312",
    ]
      // Filter duplicates
      .filter((item, index, array) => array.indexOf(item) === index)
      .map((sku) => {
        const component = Components.find((component) => component.sku === sku);
        const componentLowerName = component?.name.toLowerCase();
        let group: TMediaProductGroup = "other";

        if (componentLowerName?.includes("wall")) {
          group = "wall";
        } else if (componentLowerName?.includes("flat")) {
          group = "flat";
        } else if (componentLowerName?.includes("black")) {
          group = "black";
        }

        return {
          archived: !component?.active,
          categories: ["productcard"],
          type: "pdf",
          group: group,
          language: "en",
          title: `${sku} - ${component?.name || ""}`,
          url: `/static/material/productcard/en/${sku}.pdf`,
          staticUrl: `product-card-${sku}.pdf`,
          sortOrder: 0,
        };
      }),
  ]
    .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
    .sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1))
    .sort((a, b) => (a.language < b.language ? -1 : 1))
    .map((x, i) => ({ ...x, index: i } as IPresortedMedia))
    .sort((a, b) =>
      a.language === b.language
        ? a.index < b.index
          ? -1
          : 1
        : a.language === language
        ? -1
        : a.language === "en" && b.language !== "en"
        ? -1
        : a.index < b.index
        ? -1
        : 1
    );
};

export default getMedia;

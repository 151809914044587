import { IConfiguration } from "./getConfig.interfaces";

const ProductionConfig: IConfiguration = {
  environment: "production",
  requestQuote: "https://app.raulibrackets.fi/api/v1/requestQuote",
  systemLoads: "https://app.raulibrackets.fi/api/v1/systemLoads",
  estimateProduction: "https://app.raulibrackets.fi/api/v1/estimateProduction",
  webshopOrder: "https://app.raulibrackets.fi/api/v1/webshopOrder",
  appUrl: "https://app.raulibrackets.fi",
  mapsToken: "AIzaSyBp43urdvTA14pOo5nLBYuCXtTqGXFHC04",
  firebaseConfig: {
    apiKey: "AIzaSyA9hH1lDwCvRh9F01WYw_22s5x9XsIN98Q",
    authDomain: "raulibrackets.firebaseapp.com",
    projectId: "raulibrackets",
    storageBucket: "raulibrackets.appspot.com",
    messagingSenderId: "729247532560",
    appId: "1:729247532560:web:e00e437b8d32e14b2d2c95",
  },
  maximumSnowLoad: 5000,
  maximumWindLoad: 2000,
  panelDimensions: {
    width: {
      min: 900,
      max: 1400,
    },
    height: {
      min: 1600,
      max: 2400,
    },
  },
  flatroofLandscapeTilt: 13,
  flatroofPortraitTilt: 10,
  version: process.env.REACT_APP_CI_COMMIT_TAG || "production",
  tokenUrl: "https://app.raulibrackets.fi/api/v1/token",
  wallRailGapWidth: 100,
};

export default ProductionConfig;

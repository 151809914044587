import { IComponent } from "./components.interfaces";
// NOTE: When adding components, remember to add translations!

export const Components: IComponent[] = [
  {
    sku: "wall-screw-virtual",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "Wall mounting screw",
    cuttable: false,
    has_image: false,
    co2e: null,
    is_virtual: true,
    material: null,
    finish: null,
  },
  {
    sku: "RAL",
    active: true,
    kind: "extra",
    color: null,
    name: "RAL color",
    cuttable: false,
    has_image: false,
    co2e: null,
    is_virtual: false,
    material: null,
    finish: null,
  },
  {
    sku: "11055",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Black Rubber seal ring",
    dimensions: {
      weight: 0.0075,
    },
    cuttable: false,
    package: {
      weight: 0.75,
      count: 100,
    },
    has_image: true,
    co2e: null,
    material: "EPDM",
    finish: null,
  },
  {
    sku: "11056",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Black Extension ring 16mm",
    dimensions: {
      weight: 0.0035,
    },
    cuttable: false,
    package: {
      weight: 0.7,
      count: 200,
    },
    has_image: true,
    co2e: null,
    material: "Plastic",
    finish: null,
  },
  {
    sku: "11057",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Black Extension ring 21mm",
    dimensions: {
      weight: 0.0045,
    },
    cuttable: false,
    package: {
      weight: 0.9,
      count: 200,
    },
    has_image: "11056",
    co2e: null,
    material: "Plastic",
    finish: null,
  },
  {
    sku: "11058",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "RAULI Black Roof safety screw 7x50",
    dimensions: {
      weight: 0.004,
    },
    cuttable: false,
    package: {
      weight: 0.8,
      count: 200,
    },
    has_image: true,
    co2e: null,
    material: "Hardened steel",
    finish: "Xylan + Powder coat",
  },
  {
    sku: "11059",
    active: true,
    kind: "accessory",
    color: "black",
    name: "RAULI Black Bolt M8x20 Black",
    dimensions: {
      weight: 0.0155,
    },
    cuttable: false,
    package: {
      width: 0.12,
      length: 0.1,
      height: 0.8,
      weight: 1.55,
      count: 100,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11060",
    active: false,
    kind: "accessory",
    color: "black",
    name: "RAULI Black Bolt M8x40 Black",
    cuttable: false,
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11066",
    active: true,
    kind: "end-clamp",
    color: "black",
    name: "RAULI Black End Clamp 35mm Black",
    dimensions: {
      height: 0.035,
      width: 0.043,
      weight: 0.065,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.09,
      weight: 9.75,
      count: 150,
    },
    has_image: true,
    co2e: 0.393,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11067",
    active: false,
    kind: "end-clamp",
    color: "grey",
    name: "RAULI Black End Clamp 35mm Grey",
    dimensions: {
      height: 0.035,
      width: 0.043,
      weight: 0.065,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.9,
      weight: 9.75,
      count: 150,
    },
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11068",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black All In One Bracket",
    dimensions: {
      weight: 0.55,
    },
    cuttable: false,
    package: {
      width: 0.435,
      length: 0.225,
      height: 0.07,
      weight: 16.5,
      count: 30,
    },
    wholesale_package: {
      width: 0.8,
      length: 0.12,
      height: 0.75,
      weight: 685,
      count: 1200,
    },
    has_image: true,
    co2e: 2.313,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11069",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black Strong Tile Bracket",
    dimensions: {
      weight: 0.9,
    },
    cuttable: false,
    package: {
      width: 0.59,
      length: 0.29,
      height: 0.07,
      weight: 9,
      count: 10,
    },
    has_image: true,
    co2e: 4.59,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11070",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Black Seam Roof Hook",
    dimensions: {
      weight: 0.1,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.09,
      weight: 12,
      count: 120,
    },
    has_image: true,
    co2e: 0.311,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11071",
    active: true,
    kind: "earthing",
    color: null,
    name: "RAULI Black Earthing plate",
    dimensions: {
      weight: 0.0016,
    },
    cuttable: false,
    package: {
      width: 0.31,
      length: 0.215,
      height: 0.2,
      weight: 0.8,
      count: 500,
    },
    has_image: true,
    co2e: 0.000497,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11074",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Black Nordic Tile Windhook, upper",
    dimensions: {
      weight: 0.7,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.09,
      weight: 9,
      count: 50,
    },
    has_image: true,
    co2e: 0.838,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11075",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Black Nordic Tile Windhook, lower",
    dimensions: {
      weight: 0.16,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.09,
      weight: 8,
      count: 50,
    },
    has_image: true,
    co2e: 0.655,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11076",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black T45 profile roof",
    dimensions: {
      weight: 0.65,
    },
    cuttable: false,
    package: {
      width: 0.59,
      length: 0.29,
      height: 0.07,
      weight: 13,
      count: 20,
    },
    has_image: false,
    co2e: 1.98,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11078",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "RAULI Black Screw 7x40mm Wall/T35/T45",
    dimensions: {
      weight: 0.004,
    },
    cuttable: false,
    package: {
      weight: 0.8,
      count: 200,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11079",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "RAULI Black Sealing tape 25m T35/T45",
    dimensions: {
      weight: 0.1,
      length: 25,
    },
    cuttable: true,
    package: {
      weight: 0.1,
      count: 1,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11080",
    active: true,
    kind: "accessory",
    color: "grey",
    name: "RAULI Black Bolt M8x20 Grey",
    dimensions: {
      weight: 0.011,
    },
    cuttable: false,
    package: {
      weight: 1.1,
      count: 100,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11081",
    active: true,
    kind: "accessory",
    color: "grey",
    name: "RAULI Black Bolt M8x40 Grey",
    dimensions: {
      weight: 0.0175,
    },
    cuttable: false,
    package: {
      weight: 1.75,
      count: 100,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11082",
    active: true,
    kind: "accessory",
    color: "grey",
    name: "RAULI Black Nut M8 Grey",
    dimensions: {
      weight: 0.0056,
    },
    cuttable: false,
    package: {
      weight: 0.56,
      count: 100,
    },
    has_image: true,
    co2e: null,
    material: "Steel, hot dip galvanized",
    finish: null,
  },
  {
    sku: "11083",
    active: true,
    kind: "rail",
    color: "black",
    name: "RAULI Black Profile rail 0,3m Black",
    dimensions: {
      length: 0.3,
      weight: 0.4,
    },
    cuttable: false,
    package: {
      width: 0.8,
      length: 0.12,
      height: 0.27,
      weight: 120,
      count: 300,
    },
    wholesale_package: {
      width: 0.8,
      length: 0.12,
      height: 0.27,
      weight: 143,
      count: 300,
    },
    has_image: true,
    co2e: 1.115,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11086",
    active: false,
    kind: "clamp",
    color: "black",
    name: "RAULI Black Middle Clamp 35mm Black",
    dimensions: {
      width: 0.03,
      height: 0.035,
    },
    cuttable: false,
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11087",
    active: false,
    kind: "clamp",
    color: "grey",
    name: "RAULI Black Middle Clamp 35mm Grey",
    dimensions: {
      width: 0.03,
      height: 0.035,
    },
    cuttable: false,
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11088",
    active: true,
    kind: "earthing",
    color: null,
    name: "RAULI Black Earthing bolt M6x25",
    dimensions: {
      weight: 0.01,
    },
    cuttable: false,
    package: {
      weight: 1,
      count: 100,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11089",
    active: true,
    kind: "earthing",
    color: null,
    name: "RAULI Black Earthing nut M6",
    dimensions: {
      weight: 0.00115,
    },
    cuttable: false,
    package: {
      weight: 0.115,
      count: 100,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11090",
    active: true,
    kind: "earthing",
    color: null,
    name: "RAULI Black Earthing clamp M6/6",
    dimensions: {
      weight: 0.008,
    },
    cuttable: false,
    package: {
      width: 0.1,
      length: 0.65,
      height: 0.5,
      weight: 0.8,
      count: 100,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11091",
    active: true,
    kind: "rail",
    color: "black",
    name: "RAULI Black Profile rail 5,30m Black",
    dimensions: {
      length: 5.3,
      weight: 6.94,
    },
    cuttable: false,
    package: {
      width: 1.15,
      length: 5.3,
      height: 0.27,
      weight: 2082,
      count: 300,
    },
    wholesale_package: {
      width: 1.15,
      length: 5.3,
      height: 0.27,
      weight: 2107,
      count: 300,
    },
    has_image: "11139",
    co2e: 19.695,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11093",
    active: false,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall Top Rail, 3m",
    dimensions: {
      length: 3,
      weight: 10.87,
    },
    cuttable: true,
    package: {
      count: 2,
      width: 0.075,
      length: 0.18,
      height: 3,
    },
    wholesale_package: {
      weight: 641,
      count: 60,
      width: 1.2,
      length: 0.5,
      height: 3,
    },
    has_image: true,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11094",
    active: false,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall Bottom Rail, 3m",
    dimensions: {
      length: 3,
      weight: 10.26,
    },
    cuttable: true,
    package: {
      count: 2,
      width: 0.075,
      length: 0.18,
      height: 3,
      weight: 20.52,
    },
    wholesale_package: {
      weight: 678,
      count: 60,
      width: 1.2,
      length: 0.5,
      height: 3,
    },
    has_image: true,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11095",
    active: false,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall End Profile",
    dimensions: {
      width: 0.03,
      weight: 1.6,
    },
    cuttable: false,
    package: {
      weight: 1.6,
      count: 1,
    },
    has_image: true,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11096",
    active: false,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall End Profile, short",
    dimensions: {
      width: 0.03,
      weight: 1,
    },
    cuttable: false,
    package: {
      weight: 1,
      count: 1,
    },
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11097",
    active: true,
    kind: "wall-bracket",
    color: "black",
    name: "RAULI Wall Intermediate Clamp",
    dimensions: {
      width: 0.03,
      weight: 0.0691, // Note: Differs from existing product card!
    },
    cuttable: false,
    package: {
      width: 0.11,
      length: 0.18,
      height: 0.17,
      weight: 2.6,
      count: 50,
    },
    has_image: true,
    co2e: 0.27,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11098",
    active: false,
    kind: "wall-earthing",
    color: "black",
    name: "RAULI Wall Earthing Plate",
    cuttable: false,
    dimensions: {
      weight: 0.05,
    },
    package: {
      width: 0.31,
      length: 0.215,
      height: 0.2,
      weight: 12.5,
      count: 250,
    },
    has_image: true,
    co2e: null,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11099",
    active: true,
    kind: "wall-accessory",
    color: "black",
    name: "RAULI Wall Screw 6,3x38mm drill head",
    dimensions: {
      weight: 0.01,
    },
    cuttable: false,
    package: {
      weight: 2,
      count: 200,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11100",
    active: true,
    kind: "wall-accessory",
    color: null,
    name: "RAULI Wall Extension part",
    cuttable: false,
    has_image: true,
    co2e: 3.585,
    dimensions: {
      weight: 0.29,
    },
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11102",
    active: true,
    kind: "wall-accessory",
    color: null,
    name: "RAULI Wall Vertical Beam",
    cuttable: false,
    has_image: true,
    co2e: 13.185,
    dimensions: {
      weight: 4.52,
    },
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11103",
    active: true,
    kind: "wall-accessory",
    color: "black",
    name: "RAULI Wall Screw 6,3x19mm drill head",
    cuttable: false,
    package: {
      count: 200,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11104",
    active: true,
    kind: "wall-accessory",
    color: "black",
    name: "RAULI Wall Screw 6,3x32mm drill head",
    cuttable: false,
    package: {
      count: 200,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11123",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black Nordic Tile Bracket",
    has_image: true,
    co2e: 3.81,
    dimensions: {
      weight: 0.9,
    },
    cuttable: false,
    package: {
      width: 0.59,
      length: 0.29,
      height: 0.07,
      weight: 18,
      count: 20,
    },
    wholesale_package: {
      width: 0.8,
      length: 1.2,
      height: 0.95,
      weight: 457,
      count: 480,
    },
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11128",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "RAULI Black Screw 4,8x25mm",
    dimensions: {
      weight: 0.00464,
    },
    cuttable: false,
    package: {
      weight: 1.16,
      count: 250,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11133",
    active: false,
    kind: "end-clamp",
    color: "grey",
    name: "RAULI Black End Clamp 30mm Grey",
    dimensions: {
      height: 0.03,
      weight: 0.065,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.09,
      weight: 9.75,
      count: 150,
    },
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11134",
    active: true,
    kind: "end-clamp",
    color: "black",
    name: "RAULI Black End Clamp 30mm Black",
    dimensions: {
      height: 0.03,
      width: 0.043,
      weight: 0.065,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.09,
      weight: 9.75,
      count: 150,
    },
    has_image: "11066",
    co2e: 0.443,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11137",
    active: false,
    kind: "clamp",
    color: "black",
    name: "RAULI Black Middle Clamp 30mm Black",
    dimensions: {
      width: 0.03,
      height: 0.03,
    },
    cuttable: false,
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11138",
    active: false,
    kind: "clamp",
    color: "grey",
    name: "RAULI Black Middle Clamp 30mm Grey",
    dimensions: {
      width: 0.03,
      height: 0.03,
    },
    cuttable: false,
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11139",
    active: true,
    kind: "rail",
    color: "black",
    name: "RAULI Black Profile rail 2,45m Black",
    dimensions: {
      length: 2.45,
      weight: 3.21,
    },
    cuttable: false,
    package: {
      width: 1.15,
      length: 2.45,
      height: 0.27,
      weight: 963,
      count: 300,
    },
    wholesale_package: {
      width: 1.15,
      length: 2.45,
      height: 0.27,
      weight: 988,
      count: 300,
    },
    has_image: true,
    co2e: 9.104,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11140",
    active: true,
    kind: "rail",
    color: "black",
    name: "RAULI Black Profile rail 1,22m Black",
    dimensions: {
      length: 1.22,
      weight: 1.6,
    },
    cuttable: false,
    package: {
      width: 1.15,
      length: 1.22,
      height: 0.27,
      weight: 480,
      count: 300,
    },
    wholesale_package: {
      width: 1.15,
      length: 1.22,
      height: 0.27,
      weight: 505,
      count: 300,
    },
    has_image: true,
    co2e: 4.534,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11141",
    active: true,
    kind: "rail",
    color: "black",
    name: "RAULI Black Profile rail 2,39m Black",
    dimensions: {
      length: 2.39,
      weight: 3.13,
    },
    cuttable: false,
    package: {
      width: 1.15,
      length: 2.39,
      height: 0.27,
      weight: 939,
      count: 300,
    },
    wholesale_package: {
      width: 1.15,
      length: 2.39,
      height: 0.27,
      weight: 964,
      count: 300,
    },
    has_image: "11139",
    co2e: 8.881,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11142",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black Beaver Tail Tile Bracket",
    dimensions: {
      weight: 0.55,
    },
    cuttable: false,
    package: {
      width: 0.59,
      length: 0.29,
      height: 0.07,
      weight: 11,
      count: 20,
    },
    has_image: false,
    co2e: 3.19,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11143",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black T35 profile roof",
    dimensions: {
      weight: 0.65,
    },
    cuttable: false,
    package: {
      width: 0.59,
      length: 0.29,
      height: 0.07,
      weight: 13,
      count: 20,
    },
    has_image: false,
    co2e: 2.22,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11144",
    active: false,
    kind: "bracket",
    color: "black",
    name: "RAULI Black Mini metal profile roof",
    cuttable: false,
    has_image: false,
    co2e: null,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11145",
    active: true,
    kind: "accessory",
    color: null,
    name: "RAULI Quick Guide A5",
    cuttable: false,
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11146",
    active: true,
    kind: "clamp",
    color: "black",
    name: "RAULI Black Middle Clamp 30-35mm Black",
    dimensions: {
      width: 0.03,
      height: 0.035,
      weight: 0.2,
    },
    cuttable: false,
    package: {
      width: 0.44,
      length: 0.315,
      height: 0.21,
      weight: 10,
      count: 50,
    },
    wholesale_package: {
      width: 0.8,
      length: 1.2,
      height: 1,
      weight: 345,
      count: 1600,
    },
    has_image: true,
    co2e: 0.866,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11153",
    active: true,
    kind: "end-clamp",
    color: "black",
    name: "RAULI Black Glass end clamp 32mm Black",
    cuttable: false,
    has_image: false,
    co2e: 1.229,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11154",
    active: true,
    kind: "clamp",
    color: "black",
    name: "RAULI Black Glass Middle Clamp 30-35mm Black",
    dimensions: {
      weight: 0.2,
    },
    cuttable: false,
    package: {
      weight: 10,
      count: 50,
    },
    has_image: false,
    co2e: 1.2229,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11155",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "RAULI Black Nordic Add-On Thin Lath",
    dimensions: {
      weight: 0.2,
    },
    cuttable: false,
    package: {
      width: 0.36,
      length: 0.26,
      height: 0.09,
      weight: 20,
      count: 100,
    },
    has_image: true,
    co2e: 0.58,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: null,
  },
  {
    sku: "11156",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Black Bitumen Mounting Plate",
    cuttable: false,
    has_image: false,
    co2e: 3.775,
    material: null,
    finish: null,
  },
  {
    sku: "11160",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black All In One",
    dimensions: {
      weight: 0.55,
    },
    cuttable: false,
    package: {
      width: 0.435,
      length: 0.225,
      height: 0.7,
      weight: 16.5,
      count: 30,
    },
    wholesale_package: {
      width: 0.8,
      length: 0.12,
      height: 0.75,
      weight: 685,
      count: 1200,
    },
    has_image: true,
    co2e: 2.313,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11161",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Black Seam Roof",
    dimensions: {
      weight: 0.55,
    },
    cuttable: false,
    package: {
      width: 0.435,
      length: 0.225,
      height: 0.7,
      weight: 16.5,
      count: 30,
    },
    wholesale_package: {
      width: 0.8,
      length: 0.12,
      height: 0.75,
      weight: 685,
      count: 1200,
    },
    has_image: true,
    co2e: 2.313,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11163",
    active: true,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall End List Universal, 30mm",
    cuttable: false,
    dimensions: {
      width: 0.03,
      weight: 0.167,
    },
    package: {
      weight: 6.68,
      count: 40,
      width: 0.36,
      length: 0.26,
      height: 0.09,
    },
    has_image: true,
    co2e: 0.71,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11164",
    active: true,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall End List Universal, 35mm",
    cuttable: false,
    dimensions: {
      width: 0.03,
      weight: 0.187,
    },
    package: {
      weight: 7.48,
      count: 40,
      width: 0.36,
      length: 0.26,
      height: 0.09,
    },
    has_image: true,
    co2e: 0.82,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11166",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "Screw Ejot 2.5x25 SS",
    cuttable: false,
    has_image: true,
    co2e: null,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11174",
    active: true,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall Top Rail, 3m",
    cuttable: true,
    dimensions: {
      length: 3,
      weight: 7.95,
    },
    package: {
      // Note: Dimensions don't seem to make any sense
      weight: 477,
      count: 60,
      width: 0.075,
      length: 0.18,
      height: 3,
    },
    has_image: true,
    co2e: 23.16,
    material: "Galfan steel 300 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11175",
    active: true,
    kind: "wall-rail",
    color: "black",
    name: "RAULI Wall Bottom Rail, 3m",
    cuttable: true,
    dimensions: {
      length: 3,
      weight: 7.95,
    },
    package: {
      // Note: Dimensions don't seem to make any sense
      weight: 477,
      count: 60,
      width: 0.075,
      length: 0.18,
      height: 3,
    },
    has_image: true,
    co2e: 23.16,
    material: "Galfan steel 300 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11176",
    active: true,
    kind: "wall-earthing",
    color: "black",
    name: "RAULI Wall Earthing Plate",
    dimensions: {
      weight: 0.05,
    },
    cuttable: false,
    package: {
      width: 0.31,
      length: 0.215,
      height: 0.2,
      weight: 12.5,
      count: 250,
    },
    has_image: false,
    co2e: 0,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11180",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "Screw Ejot 2.5x25 SS Black",
    cuttable: false,
    has_image: false,
    co2e: null,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11181",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "Screw Ejot 2.5x35 SS Black",
    cuttable: false,
    has_image: false,
    co2e: null,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11300",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Flat Triangle RR33",
    cuttable: false,
    dimensions: {
      weight: 1.67,
    },
    has_image: true,
    co2e: 6.14,
    material: "Galfan steel 300 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11301",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Mounting Plate",
    cuttable: false,
    dimensions: {
      weight: 1.75,
      length: 0.6,
      width: 0.3,
    },
    has_image: true,
    co2e: 5.08,
    material: "Galvanized steel 350 g/m²",
    finish: null,
  },
  {
    sku: "11302",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Cable Channel",
    cuttable: false,
    dimensions: {
      weight: 0.46,
    },
    has_image: true,
    co2e: 4.38,
    material: "Galvanized steel 275 g/m²",
    finish: "Nova color coated",
  },
  {
    sku: "11303",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Snow Support",
    cuttable: false,
    dimensions: {
      weight: 1.86,
    },
    has_image: true,
    co2e: 6.38,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11304",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Wind Plate 1.85 m",
    cuttable: false,
    dimensions: {
      weight: 2.6,
    },
    has_image: true,
    co2e: 6.61,
    material: "Galvanized steel 275 g/m²",
    finish: "Nova color coated",
  },
  {
    sku: "11305",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat End Support",
    cuttable: false,
    dimensions: {
      weight: 0.1,
    },
    has_image: true,
    co2e: 0.8,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11306",
    active: true,
    kind: "bracket",
    color: null,
    name: "RAULI Flat Triangle Galfan",
    cuttable: false,
    dimensions: {
      weight: 1.67,
    },
    has_image: true,
    co2e: 5.08,
    material: "Galfan steel 300 g/m²",
    finish: null,
  },
  {
    sku: "11307",
    active: true,
    kind: "rail",
    color: "black",
    name: "RAULI Flat Rail 1875mm",
    cuttable: false,
    dimensions: {
      length: 1.875,
      weight: 2.46,
    },
    has_image: "11313",
    co2e: 6.79,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11308",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Bitumen Felt",
    cuttable: false,
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11309",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Flat Triangle RR33 Portrait",
    cuttable: false,
    dimensions: {
      weight: 1.97,
    },
    has_image: true,
    co2e: 9.57,
    material: "Galfan steel 300 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11310",
    active: true,
    kind: "bracket",
    color: null,
    name: "RAULI Flat Triangle Galfan Portrait",
    cuttable: false,
    dimensions: {
      weight: 1.97,
    },
    has_image: true,
    co2e: 8.67,
    material: "Galfan steel 300 g/m²",
    finish: null,
  },
  {
    sku: "11311",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "RAULI Flat Bolt M8x20 SS for Plate",
    cuttable: false,
    has_image: true,
    co2e: null,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11312",
    active: true,
    kind: "bracket-accessory",
    color: null,
    name: "RAULI Flat Nut M8 SS for Plate",
    cuttable: false,
    has_image: true,
    co2e: null,
    material: "Stainless steel",
    finish: null,
  },
  {
    sku: "11313",
    active: true,
    kind: "rail",
    color: "black",
    name: "RAULI Flat Rail 1600mm",
    cuttable: false,
    dimensions: {
      length: 1.6,
      weight: 2.1,
    },
    has_image: true,
    co2e: 5.824,
    material: "Galvanized High-strength steel 350 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11314",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat End Support Portrait",
    cuttable: false,
    dimensions: {
      weight: 0.1,
    },
    has_image: false,
    co2e: 0.88,
    material: null,
    finish: null,
  },
  {
    sku: "11315",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Rubber Seal Ring",
    cuttable: false,
    package: {
      weight: 0.75,
      count: 100,
    },
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11316",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Rubber Slat",
    cuttable: false,
    has_image: false,
    co2e: null,
    material: null,
    finish: null,
  },
  {
    sku: "11319",
    active: true,
    kind: "bracket",
    color: "black",
    name: "RAULI Flat Triangle Large RR33 Portrait",
    cuttable: false,
    dimensions: {
      weight: 1.97,
    },
    has_image: false,
    co2e: 9.57,
    material: "Galfan steel 300 g/m²",
    finish: "Powder coated, impact resistant",
  },
  {
    sku: "11320",
    active: true,
    kind: "bracket",
    color: null,
    name: "RAULI Flat Triangle Large Galfan Portrait",
    cuttable: false,
    dimensions: {
      weight: 1.97,
    },
    has_image: false,
    co2e: 8.67,
    material: "Galfan steel 300 g/m²",
    finish: null,
  },
  {
    sku: "11324",
    active: true,
    kind: "bracket-accessory",
    color: "black",
    name: "RAULI Flat Wind Plate 1.55 m",
    cuttable: false,
    dimensions: {
      weight: 2.6,
    },
    has_image: false,
    co2e: 6.61,
    material: "Galvanized steel 275 g/m²",
    finish: "Nova color coated",
  },
  {
    sku: "FU-108-370-M-RD",
    active: false,
    kind: "panel",
    color: "red",
    name: "FuturaSun Silk® Nova Red",
    cuttable: false,
    has_image: false,
    co2e: null,
    dimensions: {
      length: 1.722,
      width: 1.134,
      height: 0.03,
      weight: 20.8,
    },
    power: 370,
    material: null,
    finish: null,
  },
  {
    sku: "FU-108-380-M-OR",
    active: false,
    kind: "panel",
    color: "orange",
    name: "FuturaSun Silk® Nova Orange",
    cuttable: false,
    has_image: false,
    co2e: null,
    dimensions: {
      length: 1.722,
      width: 1.134,
      height: 0.03,
      weight: 20.8,
    },
    power: 380,
    material: null,
    finish: null,
  },
  {
    sku: "FU-108-390-M-GN",
    active: false,
    kind: "panel",
    color: "green",
    name: "FuturaSun Silk® Nova Green",
    cuttable: false,
    has_image: false,
    co2e: null,
    dimensions: {
      length: 1.722,
      width: 1.134,
      height: 0.03,
      weight: 20.8,
    },
    power: 390,
    material: null,
    finish: null,
  },
  {
    sku: "FU-108-390-M-SI",
    active: false,
    kind: "panel",
    color: "silver",
    name: "FuturaSun Silk® Nova Silver",
    cuttable: false,
    has_image: false,
    co2e: null,
    dimensions: {
      length: 1.722,
      width: 1.134,
      height: 0.03,
      weight: 20.8,
    },
    power: 390,
    material: null,
    finish: null,
  },
];

export default Components;
